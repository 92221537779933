import { render, staticRenderFns } from "./LandingDemoHeader.vue?vue&type=template&id=cf192ffc"
import script from "./LandingDemoHeader.vue?vue&type=script&lang=js"
export * from "./LandingDemoHeader.vue?vue&type=script&lang=js"
import style0 from "./LandingDemoHeader.vue?vue&type=style&index=0&id=cf192ffc&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingDemoLogo: require('/builds/project/volu/frontend/components/landing/demo/header/LandingDemoLogo.vue').default,LandingDemoMenu: require('/builds/project/volu/frontend/components/landing/demo/header/LandingDemoMenu.vue').default})
